<template>
  <section id="exhibit" class="exhibit">
    <div class="wrap1160 exhibit-wrap">
      <h1>{{ $t('INSPECTION_PURCHASE_REQUEST_FROM_TITLE') }}</h1>
      <form class="entry-result" id="entry-form">
        <div class="entry-formInner bgLGray">
          <div class="textarea-wrap mt0_sp">
            <p class="form-wrap-tit pr0">{{ $t('INSPECTION_AUCTION_REQUEST_COMMENT_LABEL') }}</p>
            <div class="form-wrap-input">
              <textarea class="full" cols="30" rows="3" v-model="comment"></textarea>
            </div>
          </div>
          <div class="form-wrap flexStart">
            <div class="form-wrap-choice" style="width: 100%;">
              <div class="form-wrap-choice-btnWrap" style="justify-content: center;">
                <a class="form-wrap-choice-btn normal js-popup" name="auction" @click="requestCategoryId = '2'; agency()">
                  {{ $t('INSPECTION_PURCHASE_REQUEST_BUTTON_LABEL') }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <h2>{{requestResultData.request_category_name}}依頼結果</h2>
        <RequestResultDetailPreview
          :isPreview="true"
          :requestResultData="requestResultData"
          :judgeStatusPreview="false"
        />

        <h2>{{ $t('INSPECTION_AUCTION_DETAIL_FROM_TITLE') }}</h2>
        <RequestDetailPreview
          :isPreview="true"
          :manageNo="manageNo"
          :requestTitle="requestTitle"
          :comment="commentPreview"
          :commonData="commonData"
          :identityFiles="identityFiles"
          :bodyTypes="bodyTypes"
          :constants="constants"
          :getPreviewData="inspectionPreviewStore.getPreviewData"
          :imageItems="inspectionPreviewStore.itemImages"
          :imageOptionals="inspectionPreviewStore.optionalImages"
          :documentFiles="inspectionPreviewStore.documentFiles"
          :errors="{}"
        />

        <div class="btn-form text-center mt-3">
          <input
            type="button"
            class="btn-back"
            :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')"
            @click="$routerGo($define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)"
          >
          <input
            type="button"
            class="blue"
            :value="$t('INSPECTION_AUCTION_REQUEST_TOP_BTN_LABEL')"
            @click="gotoTop"
          >
        </div>

        <!-- <div class="btnWrap alignStart">
          <div>
            <a class="formBtn orange js-popup" name="agency" @click="requestCategoryId = '3'; agency()">{{ $t('INSPECTION_AUCTION_REQUEST_AGENCY_BUTTON_LABEL') }}</a>
          </div>
          <div><a class="formBtn blue js-popup" name="auction" @click="requestCategoryId = '4'; auction()">{{ $t('INSPECTION_AUCTION_REQUEST_BUTTON_LABEL') }}</a></div>
        </div> -->
      </form>
    </div>
  </section>
</template>

<script>
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import useInspectionPreview from '@/stores/useInspectionPreview'
import useLoading from '@/stores/useLoading'
import useRequestResultPreview from '@/stores/useRequestResultPreview'
import {defineComponent} from '@vue/composition-api'
import RequestDetailPreview from './parts/RequestDetailPreview'
import RequestResultDetailPreview from './parts/RequestResultDetailPreview'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionPreviewStore = useInspectionPreview()
    const auctionRequestStore = inspectionAuctionRequest()
    const resultPreviewStore = useRequestResultPreview()
    return {
      loadingStore,
      inspectionPreviewStore,
      auctionRequestStore,
      resultPreviewStore
    }
  },
  components : {
    RequestDetailPreview,
    RequestResultDetailPreview
  },
  props : {},
  data() {
    return {
      isOpenDialog : false
    }
  },
  computed : {
    detailData() {
      return this.auctionRequestStore.detailData
    },
    comment : {
      get() {
        return this.auctionRequestStore.comment
      },
      set(val) {
        this.auctionRequestStore.comment = val
      }
    },
    requestCategoryId : {
      get() {
        return this.auctionRequestStore.requestCategoryId
      },
      set(val) {
        this.auctionRequestStore.requestCategoryId = val
      }
    },
    manageNo() {
      return this.inspectionPreviewStore.manageNo
    },
    requestTitle() {
      return this.inspectionPreviewStore.requestTitle
    },
    commentPreview() {
      return this.inspectionPreviewStore.comment
    },
    commonData() {
      return this.inspectionPreviewStore.commonData
    },
    constants() {
      return this.inspectionPreviewStore.constants
    },
    identityFiles() {
      return this.inspectionPreviewStore.identityFiles
    },
    bodyTypes() {
      return this.inspectionPreviewStore.bodyTypes
    },
    requestResultData() {
      return this.resultPreviewStore.requestResultData
    }
  },
  methods : {
    isNumber(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      }
      return true
    },
    agency() {
      this.auctionRequestStore.agency()
    },
    gotoTop() {
      window.scrollTo({
        top      : 0,
        left     : 0,
        behavior : 'smooth'
      })
    }
  },
  watch : {
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
