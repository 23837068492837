<template>
<main id="main">
  <section id="exhibit" class="exhibit">
    <Form v-if="requestFlag" :parent="$data"/>
    <Completion v-else-if="completedFlag" :parent="$data" />
    <ComfirmForm v-else :parent="$data"/>
  </section>
</main>
</template>

<script>
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import useInspectionPreview from '@/stores/useInspectionPreview'
import useRequestResultPreview from '@/stores/useRequestResultPreview'
import {defineComponent} from '@vue/composition-api'
import ComfirmForm from '../../components/Inspection/PurchaseRequestComfirm'
import Completion from '../../components/Inspection/PurchaseRequestCompletion'
import Form from '../../components/Inspection/PurchaseRequestForm'
export default defineComponent({
  setup() {
    const auctionRequestStore = inspectionAuctionRequest()
    const inspectionPreviewStore = useInspectionPreview()
    const resultPreviewStore = useRequestResultPreview()
    return {
      auctionRequestStore,
      inspectionPreviewStore,
      resultPreviewStore
    }
  },
  components : {
    Form,
    ComfirmForm,
    Completion
  },
  data() {
    return {
      identityFiles   : [],
      authLetterFiles : [],
    }
  },
  computed : {
    requestFlag() {
      return this.auctionRequestStore.requestFlag
    },
    completedFlag() {
      return this.auctionRequestStore.completedFlag
    },
    detailData() {
      return this.inspectionAuctionRequest.detailData
    },
  },
  created() {
    this.auctionRequestStore.resetData()
    this.inspectionPreviewStore.resetData()
    this.resultPreviewStore.resetData()
  },
  mounted() {
    const itemRequestNo = this.auctionRequestStore.selectItemRequestNo
    if (itemRequestNo) {
      this.inspectionPreviewStore.getConstants(itemRequestNo, this.$i18n.locale)
      this.resultPreviewStore.getResult(itemRequestNo, this.$i18n.locale)
    }
    else this.$routerGo($define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)
  },
  methods : {}
})
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
